import React from "react"
import { Card } from "react-bootstrap"
import one from "../images/newDesign/training/1.jpg"
import two from "../images/newDesign/training/2.jpg"
import three from "../images/newDesign/training/3.jpg"
import ESGE from "../images/newDesign/training/ESGE.png"
import AwardDeadlineBanner from "../components/AwardDeadlineBanner"
import broucher from "../pdfs/BROCHUREORIENTATIOPROGRAMONESG.pdf"
import { Link } from "gatsby"
import AnilKumar from "../images/newDesign/carousalImages/Anil_kumar.png"
import Sachin from "../images/newDesign/carousalImages/UWD_CEO_SachinGol.png"
import Dinesh from "../images/newDesign/carousalImages/2.jpg"
import Mohan from "../images/newDesign/carousalImages/3.jpg"
import Purnamita from "../images/newDesign/carousalImages/4.jpg"
import Carousel from "react-multi-carousel"
export default function TrainingPrograms() {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }

  const advisors = [
    {
      url: Mohan,
      name: "Mr. Mohan Soni",
      designation: "Founder Partner TAMS & CO LLP",
      linkedin:
        "https://www.linkedin.com/in/mohan-soni-2a81ba103/?originalSubdomain=in",
    },
    {
      url: Dinesh,
      name: "Mr. Dinesh Agrawal",
      designation: "Principal Consultant Consocia Advisory",
      linkedin: "https://www.linkedin.com/in/dinagrawal/?originalSubdomain=in",
    },
    {
      url: Purnamita,
      name: "Prof. Purnamita Dasgupta",
      designation:
        "Chair, Environmental Economics and Head, Environmental and Resource Economics Unit Institute of Economic Growth",
      // linkedin: "",
    },
    {
      url: Sachin,
      name: "Mr. Sachin Golwalkar ",
      designation: "CEO, United Way Delhi",
      linkedin:
        "https://www.linkedin.com/in/sachin-golwalkar-4244144/?original_referer=https%3A%2F%2Fwww%2Egoogle%2Ecom%2F&originalSubdomain=in",
    },
  ]
  return (
    <>
      <div className="mt-5 px-0 px-md-5 px-lg-5 px-xl-5 container-custom">
        {/* <div className="">
          <br />
          <Link to="/training-form" className="btn learn-more-btn  my-4">
            CLICK TO JOIN THE PROGRAME
          </Link>
          <a
            target="_blank"
            href={broucher}
            className="btn learn-more-btn my-4 mx-0 mx-sm-0 mx-md-2 mx-lg-0 mx-xl-3 "
          >
            CLICK HERE TO DOWNLOAD THE BROCHURE
          </a>
        </div>

        <h1 className="heading pt-5 mb-5">
          ORIENTATION PROGRAM (OFFLINE) ON ESG
        </h1>
        <div className="row text-center px-2">
          <div
            className="col-md-4 border-end comman-para"
            style={{ background: "#5b0017c4", color: "#fff" }}
          >
            4th AUGUST 2023
          </div>
          <div
            className="col-md-4 border-end comman-para"
            style={{ background: "#5b0017c4", color: "#fff" }}
          >
            Time (IST) : 10:00 AM - 5:00 PM
          </div>
          <div
            className="col-md-4 comman-para"
            style={{ background: "#5b0017c4", color: "#fff" }}
          >
            REGISTRATION FEE : 6,000 PER PERSON + 18% GST
            <br />
            Non-Residential Participant Pass (With Tea, Lunch + Networking)
          </div>
        </div> */}

        {/* <AwardDeadlineBanner /> */}
        {/* <div className=" pt-5 mb-5">
          <h1 className="heading mb-5">OUR Experts</h1>
          <Carousel
            responsive={responsive}
            arrows={true}
            infinite={true}
            autoPlay={true}
          >
            {advisors.map(data => {
              return (
                <div className="m-3">
                  <Card className="speaker-cards">
                    <Card.Img variant="top" src={data.url} />
                    <Card.Body>
                      <div className="row">
                        <div className="col-9">
                          <div className="card-heading fw-bold">
                            {data.name}
                          </div>
                        </div>
                        {data.linkedin && (
                          <div className="col-3 text-end">
                            <a target="_blank" href={data.linkedin}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="30"
                                fill="currentColor"
                                class="bi bi-linkedin"
                                viewBox="0 0 16 16"
                              >
                                <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                              </svg>
                            </a>
                          </div>
                        )}
                      </div>

                      <p className="designation-text pt-2">
                        {data.designation}
                      </p>
                    </Card.Body>
                  </Card>
                </div>
              )
            })}
          </Carousel>
        </div>
        <h1 className="heading pt-3 mb-5">MAIN EVENT SESSIONS</h1>

        <div className="row">
          <div
            className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-3 border"
            style={{
              backgroundColor: "#f7f4e7",
            }}
          >
            <div class="card border-0 p-2 bg-transparent">
              <div class="card-body">
                <h5 class="card-title fw-bold h2 heading">
                  Environmental & Sustainability
                </h5>
                <p class="card-text comman-para" align="justify">
                  Understanding the impact of business operations on the
                  environment, climate change, resource conservation, pollution
                  control and renewable energy.
                </p>
              </div>
            </div>
          </div>

          <div
            className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-3 border"
            style={{
              backgroundColor: "#f7f4e7",
            }}
          >
            <div class="card border-0 p-2 bg-transparent">
              <div class="card-body">
                <h5 class="card-title fw-bold h2 heading">Social Factors</h5>
                <p class="card-text comman-para" align="justify">
                  Examining the social impact of organisations, including
                  diversity, human rights, employee and community engagement and
                  social impact assessment.
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-3 border"
            style={{
              backgroundColor: "#f7f4e7",
            }}
          >
            <div class="card border-0 p-2 bg-transparent">
              <div class="card-body">
                <h5 class="card-title fw-bold h2 heading">
                  Governance Factors
                </h5>
                <p class="card-text comman-para" align="justify">
                  Exploring NGRBC principles of good corporate governance,
                  ethical practices, transparency, accountability and the
                  board's role in overseeing ESG performance.
                </p>
              </div>
            </div>
          </div>

          <div
            className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-3 border"
            style={{
              backgroundColor: "#f7f4e7",
            }}
          >
            <div class="card border-0 p-2 bg-transparent">
              <div class="card-body">
                <h5 class="card-title fw-bold h2 heading">
                  ESG Reporting And BRSR Disclosure
                </h5>
                <p class="card-text comman-para" align="justify">
                  Understanding of Indian ESG reporting framework, BRSR
                  guidelines and illustrative cases to effectively communicate
                  ESG performance to stakeholders.
                </p>
              </div>
            </div>
          </div>
        </div> */}

        <h1 className="heading mt-5 pt-5 mb-3">KEY ESG TRAININGS</h1>
        <Link to="/esg-training" className="btn learn-more-btn  my-4">
          Register Now
        </Link>
        <div className="row">
          <div className="col-md-5">
            <img src={one} className="img-fluid" alt={``} />
          </div>
          <div
            className="col-md-7 mb-4 "
            style={{ background: "rgb(247, 244, 231)" }}
          >
            <Card className="h-100 border-0 bg-transparent p-3">
              <Card.Body className="">
                <h4 className="heading-four fw-bold">
                  ESG Training in Corporates/ MSME
                </h4>
                <div className="card-text comman-para" align="justify">
                  Indian ESG Network provides comprehensive ESG training
                  sessions for employees, covering a range of topics related to
                  Environmental & Sustainability, the Social Aspect of ESG, the
                  fundamental role of Governance in ESG, and ESG Reporting &
                  Compliances. These training sessions are conducted by experts
                  in each respective area.
                </div>
                {/* <div className="card-text comman-para">Please sign up for a ESG Training within your organization</div> */}
                <div className="text-white mt-2">
                  <Link
                    className="common-second-heading"
                    target="_blank"
                    to="/esg-training"
                  >
                    SIGN UP FOR ESG TRAINING
                  </Link>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
        <div className="row pt-5 mt-5">
          <div
            className="col-md-7 mb-4"
            style={{ background: "rgb(247, 244, 231)" }}
          >
            <Card className="h-100 border-0 bg-transparent p-3">
              <Card.Body className="">
                <h4 class="heading-four fw-bold">
                  ESG Orientation Program (Online)
                </h4>
                <div class="card-text comman-para" align="justify">
                  The ESG Orientation Program is designed to raise awareness
                  amongst employees on key concepts of ESG, latest regulatory
                  and reporting frameworks and its impact through self-paced
                  online mode. The training will cover key aspects of
                  Environment, Social & Governance and BRSR reporting
                  requirements for a sustainable business.
                </div>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-5">
            <img src={two} className="img-fluid" alt={``} />
          </div>
        </div>
        <div className="row pt-5 mt-5">
          <div className="col-md-5">
            <img src={one} className="img-fluid" alt={``} />
          </div>
          <div
            className="col-md-7 mb-4 "
            style={{ background: "rgb(247, 244, 231)" }}
          >
            <Card className="h-100 border-0 bg-transparent p-3">
              <Card.Body className="">
                <h4 class="heading-four fw-bold">
                  ESG Orientation Program (Offline)
                </h4>
                <div class="card-text comman-para" align="justify">
                  This program will be customised as per needs and readiness of
                  each organisation conducted in oﬄine mode with expert faculty.
                  This will be a 4-5 hours workshop covering each aspect of ESG,
                  Reporting & Regulatory Frameworks along with case studies.
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
        <div className="row pt-5 mt-5">
          <div
            className="col-md-7 mb-4"
            style={{ background: "rgb(247, 244, 231)" }}
          >
            <Card className="h-100 border-0 bg-transparent p-3">
              <Card.Body className="">
                <h4 class="heading-four fw-bold">Social Aspect Of ESG</h4>
                <div class="card-text comman-para" align="justify">
                  The course is being designed for CSR Professionals and Social
                  Enterprises to share key role of Social projects in ESG
                  Framework and deep dive into the concepts of Social Impact
                  Assessment, Social Audit etc
                </div>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-5">
            <img src={three} className="img-fluid" alt={``} />
          </div>
        </div>
        {/* <div className="row pt-5 mt-5">
          <div className="col-md-5">
            <img src={four} className="img-fluid" />
          </div>
          <div className="col-md-7 mb-4">
            <Card className="h-100 border-0">
              <Card.Body className="">
                <h5 class="card-title fw-bold h2">
                  ESG Orientation Program(Oﬄine)
                </h5>
                <div
                  class="card-text"
                  align="justify"
                  style={{ fontSize: "20px" }}
                >
                  This program will be customised as per needs and readiness of
                  each organisation conducted in oﬄine mode with expert faculty.
                </div>
              </Card.Body>
            </Card>
          </div>
        </div> */}
      </div>
    </>
  )
}
